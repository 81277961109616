<script>
import { ArrowUpIcon, MapPinIcon,PlusIcon,MoreHorizontalIcon,MinusIcon } from "vue-feather-icons";

import Navbar from "@/components/navbar0";

import Footer from "@/components/footer";

import ResumeHighlight from "./ResumeHighlightWeaviate";


import store from "@/store/store";

export default {

  components: {
    ResumeHighlight,
    Navbar,
    Footer,
    ArrowUpIcon,
    MapPinIcon,
    PlusIcon,
    MinusIcon,
    MoreHorizontalIcon,
  },
  data() {
    return {
      hover: false,
      loadingStatus: false,
      additionalParamentersMenu: false,

      search_line_string: '',
      andOrFilter_Options : [
        {id:1,value:'Все слова'},
        {id:2,value:'Любое из слов'},
      ],
      andOrFilter_Selected : {id:1,value:'Все слова'},

      whereToSearchFilter_Options : [
        {id:1,value:'Везде'},
        {id:2,value:'В должностях'},
        {id:3,value:'В названии компании'},
        {id:4,value:'В образовании'},
        {id:5,value:'В навыках'},
        {id:6,value:'Город'},
        {id:7,value:'В ФИО'},
        {id:8,value:'В email'},
      ],
      whereToSearch_Selected : {id:1,value:'Везде'},

      additionalSearchLines: [1,2],
      advancedSearchSwitch: true,

      orderBy_Options : [
        {id:1,value:'По дате добавления резюме'},
        {id:2,value:'По дате обновления резюме'},
        {id:3,value:'По убыванию зарплат'},
        {id:4,value:'По возрастанию зарплат'},
        {id:5,value:'По убыванию возраста'},
        {id:6,value:'По возрастанию возраста'},
      ],
      orderBy_Selected : {id:1,value:'По дате добавления резюме'},

      resumesPerPage_Options : [
        {id:1,value:'10 резюме'},
        {id:2,value:'20 резюме'},
        {id:3,value:'50 резюме'},
        {id:4,value:'100 резюме'},
      ],
      resumesPerPage_Selected : {id:1,value:'10 резюме'},

      list: [{
        id: 1,
        image: 'images/shop/product/s1.jpg',
        title: 'T-Shirt',
        price: 255,
        qty: 2,
        total: 510
      },

      ],

      input_advanced_search: {
        user_id: 1,
        searchParameters: [],
        searchFlags: {},
        page:1,
        resumes_per_page:10,
        hash:'',
      },

      searchLine: '',

      search_parameters: [
        {
        leftParethesis: false,
        field: {name:'Resume headline',id:1,type:'String'},
        relationship:{name:'contains',id:2},
        value:'',
          orButton: false,
          andButton: false,
          rightParethesis:false,
          id:0
        }
        ],

      search_parameters_arr: [{
        search_line_string: '',
        andOrFilter_id: 1,
        whereToSearchFilter_id: 1,
      }],

      search_parameters_obj: {
        search_line_string: '',
            andOrFilter_id: 1,
          whereToSearchFilter_id: 1,
      },
      search_parameters_additional: [],

      search_flags: {
        switchEmail: false,
        switchContact: false,

        switchRemote: false,
        switchReadyToMove: false,

        switchHighlighView: false,
        switchResumeView: false,
        switchSaved: false,
      },

      setSQL_ids : {
        ids_arr: [1,2],
        save_search_id: 1,
        user_id: 2,
      },

      savedSearch: {name:'Базовый',id:0,type_id:0},

      resumeIds : [],
      resumes : [],

      total_resume: 5836,

      allVacancies: [
        {
          id: 0,
          name: "Дефолт",
        },
      ],
      activeVacancies: [],
      archiveVacancies: [],

      latestSearches: [],


      input_save_vacancy: {
        type_id: 1,
        user_id: 1,
        vacancy_id:0,
        toDelete: false,
        name: '',
        id: 0,
        status: 0,
      },

      totalSearchResults:1,
      page: {
        active: 1,
        up: 2,
        down: 1,
        max: 3,
        min: 1,
        left_skip: false,
        right_skip: false,
        prev: true,
        next: true,
        skip_number: 3,
      },
      page_arr:[],

      filters: {
        industryFilter: false,
        gradeFilter: false,
        lastActiveFilter: false,
        jobTypeFilter: false,

        ageFilter: true,
        experienceFilter: true,
        emailFilter: true,
        reallocateFilter: true,
        remoteFilter: true,
        salaryFilter: true,

        cityFilter: true,
        universityFilter: true,
        companyFilter: true,

      },
      /*
      field_Options : [
        {id:1,value:'Везде',field:'description'},
        {id:2,value:'Только в названиях',field:'job_title'},
        {id:3,value:'Только в навыках',field:'skills'},
      ],
      field_Selected : {id:1,value:'Везде',field:'description'},
       */

      field_Options : [
        {id:1,value:'Точное совпадение',field:'tokenized_resume'},
        {id:2,value:'Вектороный поиск',field:'tokenized_resume'},
      ],
      field_Selected : {id:2,value:'Вектороный поиск',field:'tokenized_resume'},


      search_parameters_weaviate: {
        user_id: 1991,
        message: '', limit: '25',offset:'1', certainty: '0.6',
        city_arr: [],company_arr: [],university_arr: [],
        age_from: 20, age_to: 50, experience_from: 12, experience_to: 120,
        salary_from:0, salary_to:0,
        is_email: 'with_email', is_remote: 'no', is_reallocate: 'no',
        fields: 'tokenized_resume', is_vector: 2,
        ip: 'http://185.130.226.250',port: '9090',
       // ip: 'http://194.67.121.118',port: '9090',
      },

      search_parameters_weaviate_base: {
        message: '', limit: '25',offset:'1', certainty: '0.6',
        city_arr: [],company_arr: [],university_arr: [],
        age_from: 20, age_to: 50, experience_from: 12, experience_to: 120,
        salary_from:0, salary_to:0,
        is_email: 'with_email', is_remote: 'no', is_reallocate: 'no',
        fields: 'tokenized_resume', is_vector: 2,
        ip: 'http://185.130.226.250',port: '9090',
        //ip: 'http://194.67.121.118',port: '9090',
      },
      age_filter_arr: [
                      {value:'18-25 лет',flag:false},
                      {value:'25-30 лет',flag:false},
                      {value:'30-35 лет',flag:false},
                      {value:'35-40 лет',flag:false},
                      {value:'40+ лет',flag:false},
                        ],
      experience_filter_arr: [
        {value:'Нет опыта',flag:false},
        {value:'1-3 года',flag:false},
        {value:'3-6 лет',flag:false},
        {value:'Более 6 лет',flag:false},
          ],
      cities_filter_arr: [],
      companies_filter_arr: [],
      universities_filter_arr: [],
      is_email_filter_arr: [
        {value:'Есть email',flag:true},
        {value:'Без email',flag:false},
      ],
      is_remote_filter_arr: [
        {value:'Гововы работать удаленно',flag:false},
        {value:'Не гововы работать удаленно',flag:false},
      ],
      is_reallocate_filter_arr: [
        {value:'Готовы к переезду',flag:false},
        {value:'Не готовы к переезду',flag:false},
      ],
      salary_filter_arr: [
        {value:'меньше 50 тыс руб',flag:false},
        {value:'50-100 тыс руб',flag:false},
        {value:'100-200 тыс руб',flag:false},
        {value:'200-300 тыс руб',flag:false},
        {value:'300-500 тыс руб',flag:false},
        {value:'500+ тыс руб',flag:false},
      ],

      age_selected: [],
      experience_selected: [],
      cities_selected: [],
      companies_selected: [],
      universities_selected: [],

      is_email_selected: [{value:'Есть email',flag:true}],
      is_remote_selected: [],
      is_reallocate_selected: [],

      salary_selected: [],

      total_number_of_resumes: 1,

      count_loading:0,

      cityLimit:5,
      companiesLimit:5,
      universityLimit:5,
    };
  },
  watch: {
    field_Selected (val) {
      //this.search_parameters_weaviate.fields  = val.field;
      this.search_parameters_weaviate.is_vector  = val.id;
    },
    /* age_selected (val) {
      console.log('watch age' + ' '+  val)
      //this.search_parameters_arr[0].andOrFilter_id  = val.id;
      console.log(this.age_selected)
    },
    experience_selected (val) {
      console.log('watch experience ' + ' '+  val)
      //this.search_parameters_arr[0].andOrFilter_id  = val.id;
      console.log(this.experience_selected)
    },

    cities_selected (val) {

      console.log(' watch city '+  val)
      console.log(this.cities_selected)
      //this.search_parameters_arr[0].whereToSearchFilter_id  = val.id;

    },
    companies_selected (val) {
      console.log('watch company '+  val)
      //this.search_parameters_arr[0].andOrFilter_id  = val.id;
      console.log(this.companies_selected)
    },
    universities_selected(val) {
      console.log('watch university '+  val)
      //this.search_parameters_weaviate.message = val
      console.log(this.universities_selected)
    }*/
  },
  mounted() {


    this.getAllVacancies();
  //  this.getLatestSearches();
  },

  methods: {

    showAll(x,y) {
      if (x==='university') {
        if (y==='more') {
          this.universityLimit = 100;
        } else {
          this.universityLimit = 5;
        }
      } else if (x==='company') {
        if (y==='more') {
          this.companiesLimit = 100;
        } else {
          this.companiesLimit = 5;
        }
      } else if (x==='city') {
        if (y==='more') {
          this.cityLimit = 100;
        } else {
          this.cityLimit = 5;
        }
      }
    },

    goToSavedSearchPage(x) {
      let route = this.$router.resolve({path: '/search-candidates/'+x+'/page/1'});
      window.open(route.href, '_self');
    },

    selectionAgeChanged(x){
      console.log('select age')
      console.log(x)
      console.log(this.age_filter_arr)
      for (let i = 0; i < this.age_filter_arr.length; i++) {
        if (this.age_filter_arr[i].value!==x) {
          this.age_filter_arr[i].flag=false
        }
      }
      if (x==='18-25 лет') {
          this.search_parameters_weaviate.age_from=18
        this.search_parameters_weaviate.age_to=25
      } else if (x==='25-30 лет') {
        this.search_parameters_weaviate.age_from=25
        this.search_parameters_weaviate.age_to=30
      } else if (x==='30-35 лет') {
        this.search_parameters_weaviate.age_from=30
        this.search_parameters_weaviate.age_to=35
      } else if (x==='35-40 лет') {
        this.search_parameters_weaviate.age_from=35
        this.search_parameters_weaviate.age_to=40
      } else if (x==='40+ лет') {
        this.search_parameters_weaviate.age_from=40
        this.search_parameters_weaviate.age_to=80
      }
      this.updateWeaviateResumes('age');
    },

    selectionExperienceChanged(x){
      console.log('select experience')
      console.log(x)
      console.log(this.experience_filter_arr)
      for (let i = 0; i < this.experience_filter_arr.length; i++) {
        if (this.experience_filter_arr[i].value!==x) {
          this.experience_filter_arr[i].flag=false
        }
      }

      if (x==='Нет опыта') {
        this.search_parameters_weaviate.experience_from=0
        this.search_parameters_weaviate.experience_to=12
      } else if (x==='1-3 года') {
        this.search_parameters_weaviate.experience_from=12
        this.search_parameters_weaviate.experience_to=36
      } else if (x==='3-6 лет') {
        this.search_parameters_weaviate.experience_from=36
        this.search_parameters_weaviate.experience_to=72
      } else if (x==='Более 6 лет') {
        this.search_parameters_weaviate.experience_from=72
        this.search_parameters_weaviate.experience_to=500
      }
      this.updateWeaviateResumes('experience');
    },

    selectionEmailChanged(x){
      console.log('select email')
      console.log(x)
      console.log(this.is_email_filter_arr)
      for (let i = 0; i < this.is_email_filter_arr.length; i++) {
        if (this.is_email_filter_arr[i].value!==x) {
          this.is_email_filter_arr[i].flag=false
        }
      }

      if (x==='Есть email') {
        this.search_parameters_weaviate.is_email='with_email'
      } else if (x==='Без email') {
        this.search_parameters_weaviate.is_email='without_email'
      }
      this.updateWeaviateResumes('email');
    },


    selectionRemoteChanged(x){
      console.log('select remote')
      console.log(x)
      console.log(this.is_remote_filter_arr)
      for (let i = 0; i < this.is_remote_filter_arr.length; i++) {
        if (this.is_remote_filter_arr[i].value!==x) {
          this.is_remote_filter_arr[i].flag=false
        }
      }


      if (x==='Гововы работать удаленно') {
        this.search_parameters_weaviate.is_remote='with_remote'
      } else if (x==='Не гововы работать удаленно') {
        this.search_parameters_weaviate.is_remote='without_remote'
      }
      this.updateWeaviateResumes('remote');
    },


    selectionReallocateChanged(x){
      console.log('select reallocate')
      console.log(x)
      console.log(this.is_reallocate_filter_arr)
      for (let i = 0; i < this.is_reallocate_filter_arr.length; i++) {
        if (this.is_reallocate_filter_arr[i].value!==x) {
          this.is_reallocate_filter_arr[i].flag=false
        }
      }

      if (x==='Готовы к переезду') {
        this.search_parameters_weaviate.is_reallocate='with_reallocate'
      } else if (x==='Не готовы к переезду') {
        this.search_parameters_weaviate.is_reallocate='without_reallocate'
      }
      this.updateWeaviateResumes('reallocate');
    },

    selectionSalaryChanged(x){
      console.log('select salary')
      console.log(x)
      console.log(this.salary_filter_arr)
      for (let i = 0; i < this.salary_filter_arr.length; i++) {
        if (this.salary_filter_arr[i].value!==x) {
          this.salary_filter_arr[i].flag=false
        }
      }

      if (x==='меньше 50 тыс руб') {
        this.search_parameters_weaviate.salary_from=0
        this.search_parameters_weaviate.salary_to=50000
      } else if (x==='50-100 тыс руб') {
        this.search_parameters_weaviate.salary_from=50000
        this.search_parameters_weaviate.salary_to=100000
      } else if (x==='100-200 тыс руб') {
        this.search_parameters_weaviate.salary_from=100000
        this.search_parameters_weaviate.salary_to=200000
      } else if (x==='200-300 тыс руб') {
        this.search_parameters_weaviate.salary_from=200000
        this.search_parameters_weaviate.salary_to=300000
      } else if (x==='300-500 тыс руб') {
        this.search_parameters_weaviate.salary_from=300000
        this.search_parameters_weaviate.salary_to=500000
      } else if (x==='500+ тыс руб') {
        this.search_parameters_weaviate.salary_from=500000
        this.search_parameters_weaviate.salary_to=0
      }
      this.updateWeaviateResumes('salary');
    },


    selectionCityChanged(x){
      console.log('select city')
      console.log(x)
      /*console.log(this.cities_filter_arr)
      for (let i = 0; i < this.cities_filter_arr.length; i++) {
        if (this.cities_filter_arr[i].value!==x) {
          this.cities_filter_arr[i].flag=false
        }
      }*/
      this.cities_filter_arr = [];
      this.cities_filter_arr.push(x);
      this.search_parameters_weaviate.city_arr=[]
      this.search_parameters_weaviate.city_arr.push(x.value);
      this.updateWeaviateResumes('city');
    },

    selectionCompanyChanged(x){
      console.log('select company')
      console.log(x)
      /*console.log(this.companies_filter_arr)
      for (let i = 0; i < this.companies_filter_arr.length; i++) {
        if (this.companies_filter_arr[i].value!==x) {
          this.companies_filter_arr[i].flag=false
        }
      }*/
      this.companies_filter_arr=[];
      this.companies_filter_arr.push(x);
      this.search_parameters_weaviate.company_arr=[]
      this.search_parameters_weaviate.company_arr.push(x.value);
      this.updateWeaviateResumes('company');
    },

    selectionUniversityChanged(x){
      console.log('select university')
      console.log(x)
      /*console.log(this.universities_filter_arr)
      for (let i = 0; i < this.universities_filter_arr.length; i++) {
        if (this.universities_filter_arr[i].value!==x) {
          this.universities_filter_arr[i].flag=false
        }
      }*/
      this.universities_filter_arr = [];
      this.universities_filter_arr.push(x);
      this.search_parameters_weaviate.university_arr=[]
      this.search_parameters_weaviate.university_arr.push(x.value);
      this.updateWeaviateResumes('university');
    },


    nullSearch() {
      this.search_line_string = '';
      this.andOrFilter_Selected = {id: 1, value: 'Все слова'};
      this.whereToSearch_Selected = {id: 1, value: 'Везде'};
      this.search_parameters_arr = [{
        search_line_string: '',
        andOrFilter_id: 1,
        whereToSearchFilter_id: 1,
      }];
      this.search_parameters_additional= [];
    },


    addRemoveLine(x) {
      if (x === -1) {
        this.search_parameters_additional.push(this.search_parameters_obj);
        this.search_parameters_arr.push(this.search_parameters_obj);
      } else {
        this.search_parameters_additional.splice(x, 1);
        this.search_parameters_arr.splice(x + 1, 1);
      }
      console.log('inside addRemoveLine:')
      console.log(this.search_parameters_arr);
    },

    updateParameters(e) {
      console.log('inside updateParameters')
      console.log(e);
      this.addRemoveLine(e);
    },

    update_search_parameters_arr(e) {
      console.log('inside update_search_parameters_arr')
      //console.log(e);

      this.search_parameters_arr[e.index+1].search_line_string = e.search_line_string;
      this.search_parameters_arr[e.index+1].andOrFilter_id = e.andOrFilter_id;
      this.search_parameters_arr[e.index+1].whereToSearchFilter_id = e.whereToSearchFilter_id;
      //this.search_parameters_additional = e.splice(0, 1);
      console.log(this.search_parameters_arr);
    },


    updateResume(e) {
      console.log('inside updateResume')
      console.log('e = ' + e);

      this.setSQL_ids.ids_arr = [];
      this.setSQL_ids.ids_arr.push(this.resumes[e].id);
      this.setSQL_ids.save_search_id = this.savedSearch.id;
      this.setSQL_ids.user_id = store.state.user_id;
      console.log(this.setSQL_ids)

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/advanced-search/get-resume-byIdList-detail?access_token=' + store.state.token,
        data: this.setSQL_ids,
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then((res_in) => {

        console.log(res_in.data);
        if (res_in.data.length) {

          for (var k = 0; k < res_in.data.length; k++) {
            this.resumes.splice(e, 1, res_in.data[k]);
            console.log(' k = ' + k)
          }

        }
      });
    },



    searchWeaviateResumes() {
      this.loadingStatus=true;
      console.log('inside searchWeaviateResumes')
      this.search_parameters_weaviate.user_id = store.state.user_id;
      this.count_loading=0;
      this.search_parameters_weaviate.city_arr = [];
      this.search_parameters_weaviate.company_arr = [];
      this.search_parameters_weaviate.university_arr = [];
      this.search_parameters_weaviate.age_from = 20;
      this.search_parameters_weaviate.age_to = 50;
      this.search_parameters_weaviate.experience_from = 12;
      this.search_parameters_weaviate.experience_to = 120;

      this.search_parameters_weaviate.salary_from = 0;
      this.search_parameters_weaviate.salary_to = 0;

      this.search_parameters_weaviate.is_email = 'with_email';
      this.search_parameters_weaviate.is_remote = 'no';
      this.search_parameters_weaviate.is_reallocate = 'no';

      for (let i = 0; i < this.age_filter_arr; i++) {
        this.age_filter_arr[i].flag=false
      }
      for (let i = 0; i < this.experience_filter_arr; i++) {
        this.experience_filter_arr[i].flag=false
      }
      console.log(this.search_parameters_weaviate)


     // this.$vs.loading({
     //   container: '#div-with-loading',
     //   scale: 1,
     //   opacity: 0.1,
     // });

      this.axios({
        method: 'post',
        url: '' + this.$hostname +  '/weaviate/search-resumes-like',
        data: this.search_parameters_weaviate,
      }).then((res) => {

        this.count_loading = this.count_loading+1;
        console.log(res.data)
        this.resumes = [];
        if (res.data.length) {

          for (var k = 0; k < res.data.length; k++) {
              this.resumes.push(res.data[k]);
          }

        }
        if (this.count_loading===5) {
         // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loadingStatus=false;
        }
      }).catch((error) => {
        this.count_loading = this.count_loading+1;
        if (this.count_loading===5) {
          //this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loadingStatus=false;
        }
      });


      this.axios({
        method: 'post',
        url: '' + this.$hostname +  '/weaviate/search-resumes-get-number',
        data: this.search_parameters_weaviate,
      }).then((res) => {
        this.count_loading = this.count_loading+1;
        console.log('total')
        console.log(res.data)
            this.totalSearchResults = res.data.total
            this.page.max = Math.ceil(res.data.total / 25);
            this.paginationUtil(1);
        if (this.count_loading===5) {
          //this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loadingStatus=false;
        }
      }).catch((error) => {
        this.count_loading = this.count_loading+1;
        if (this.count_loading===5) {
          //this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loadingStatus=false;
        }
      });



      this.axios({
        method: 'post',
        url: '' + this.$hostname +  '/weaviate/search-resumes-get-city',
        data: this.search_parameters_weaviate,
      }).then((res) => {
        this.count_loading = this.count_loading+1;
        console.log('city')
        console.log(res.data)
        this.cities_filter_arr = []
        if (res.data.length) {

          for (var k = 0; k < res.data.length; k++) {
            if (res.data[k].value.length>0) {
              this.cities_filter_arr.push(res.data[k]);
            }
          }

        }

        if (this.count_loading===5) {
          //this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loadingStatus=false;
        }
      }).catch((error) => {
        this.count_loading = this.count_loading+1;
        if (this.count_loading===5) {
          //this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loadingStatus=false;
        }
      });


      this.axios({
        method: 'post',
        url: '' + this.$hostname +  '/weaviate/search-resumes-get-company',
        data: this.search_parameters_weaviate,
      }).then((res) => {
        this.count_loading = this.count_loading+1;
        console.log('company')
        console.log(res.data)
        this.companies_filter_arr = [];
        if (res.data.length) {

          for (var k = 0; k < res.data.length; k++) {
            if (res.data[k].value.length>0) {
              this.companies_filter_arr.push(res.data[k]);
            }
          }

        }

        if (this.count_loading===5) {
         // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loadingStatus=false;
        }
      }).catch((error) => {
        this.count_loading = this.count_loading+1;
        if (this.count_loading===5) {
         // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loadingStatus=false;
        }
      });



      this.axios({
        method: 'post',
        url: '' + this.$hostname +  '/weaviate/search-resumes-get-university',
        data: this.search_parameters_weaviate,
      }).then((res) => {
        this.count_loading = this.count_loading+1;
        console.log('university')
        console.log(res.data)
        this.universities_filter_arr = [];
        if (res.data.length) {

          for (var k = 0; k < res.data.length; k++) {
            if (res.data[k].value.length>0) {
              this.universities_filter_arr.push(res.data[k]);
            }
          }

        }

        if (this.count_loading===5) {
         // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loadingStatus=false;
        }
      }).catch((error) => {
        this.count_loading = this.count_loading+1;
        if (this.count_loading===5) {
         // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loadingStatus=false;
        }
      });




    },


    updateWeaviateResumes(x) {
      this.count_loading = 0;
      console.log('inside searchWeaviateResumes')

      this.search_parameters_weaviate.user_id = store.state.user_id;

      console.log(this.search_parameters_weaviate)


      //this.$vs.loading({
      //  container: '#div-with-loading',
      //  scale: 1,
      //  opacity: 0.1,
      //});

      this.loadingStatus=true;

      this.axios({
        method: 'post',
        url: '' + this.$hostname +  '/weaviate/search-resumes-like',
        data: this.search_parameters_weaviate,
      }).then((res) => {
        this.count_loading = this.count_loading+1;
        console.log(res.data)
        this.resumes = [];
        if (res.data.length) {

          for (var k = 0; k < res.data.length; k++) {
            this.resumes.push(res.data[k]);
          }

        }

        if (this.count_loading>=3) {
         // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loadingStatus=false;
        }
      }).catch((error) => {
        this.count_loading = this.count_loading+1;
        if (this.count_loading>=3) {
          // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loadingStatus=false;
        }
      });


      this.axios({
        method: 'post',
        url: '' + this.$hostname +  '/weaviate/search-resumes-get-number',
        data: this.search_parameters_weaviate,
      }).then((res) => {
        this.count_loading = this.count_loading+1;
        console.log('total')
        console.log(res.data)
        this.totalSearchResults = res.data.total
        this.page.max = Math.ceil(res.data.total / 25);
        this.paginationUtil(1);

        if (this.count_loading>=3) {
         // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loadingStatus=false;
        }
      }).catch((error) => {
        this.count_loading = this.count_loading+1;
        if (this.count_loading>=3) {
         // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loadingStatus=false;
        }
      });


      if (x!=='city' & this.cities_filter_arr.length>1) {
        this.axios({
          method: 'post',
          url: '' + this.$hostname + '/weaviate/search-resumes-get-city',
          data: this.search_parameters_weaviate,
        }).then((res) => {
          this.count_loading = this.count_loading+1;
          console.log('city')
          console.log(res.data)
          this.cities_filter_arr = []
          if (res.data.length) {

            for (var k = 0; k < res.data.length; k++) {
              this.cities_filter_arr.push(res.data[k]);
            }

          }

          if (this.count_loading>=3) {
           // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
            this.loadingStatus=false;
          }
        }).catch((error) => {
          this.count_loading = this.count_loading+1;
          if (this.count_loading>=3) {
           // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
            this.loadingStatus=false;
          }
        });

      }
      if (x!=='company' & this.companies_filter_arr.length>1) {
        this.axios({
          method: 'post',
          url: '' + this.$hostname + '/weaviate/search-resumes-get-company',
          data: this.search_parameters_weaviate,
        }).then((res) => {
          this.count_loading = this.count_loading+1;
          console.log('company')
          console.log(res.data)
          this.companies_filter_arr = [];
          if (res.data.length) {

            for (var k = 0; k < res.data.length; k++) {
              this.companies_filter_arr.push(res.data[k]);
            }

          }

          if (this.count_loading>=3) {
           // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
            this.loadingStatus=false;
          }
        }).catch((error) => {
          this.count_loading = this.count_loading+1;
          if (this.count_loading>=3) {
           // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
            this.loadingStatus=false;
          }
        });

      }

      if (x!=='university' & this.universities_filter_arr.length>1) {
        this.axios({
          method: 'post',
          url: '' + this.$hostname + '/weaviate/search-resumes-get-university',
          data: this.search_parameters_weaviate,
        }).then((res) => {
          this.count_loading = this.count_loading+1;
          console.log('university')
          console.log(res.data)
          this.universities_filter_arr = [];
          if (res.data.length) {

            for (var k = 0; k < res.data.length; k++) {
              this.universities_filter_arr.push(res.data[k]);
            }

          }
          if (this.count_loading>=3) {
           // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
            this.loadingStatus=false;
          }
        }).catch((error) => {
          this.count_loading = this.count_loading+1;
          if (this.count_loading>=3) {
           // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
            this.loadingStatus=false;
          }
        });

      }



    },



    saveResumesInSearch(setSQL_ids){
      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/events/update-was-in-search?access_token=' + store.state.token,
        data: setSQL_ids,
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
          .then((res_in) => {
            console.log('inside 2nd axios - events are logged')
          });

    },

    getAllVacancies() {

      this.input_save_vacancy.user_id = store.state.user_id;

      var url = '' + this.$hostname + '/prod/vacancy-weaviate/get-list?access_token=' + store.state.token;
      console.log('inside get all jobs ' )
      console.log(this.input_save_vacancy)
      this.axios({
        method: 'post',
        url: url,
        data: this.input_save_vacancy,
      }).then((res) => {
        this.allVacancies = res.data.arr;
        this.activeVacancies = this.allVacancies.filter(x => x.status === 'Активная');
        this.archiveVacancies = this.allVacancies.filter(x => x.status === 'Архивная');

        console.log(this.allVacancies);
        console.log(this.activeVacancies);
        /*
          if (this.allVacancies.length) {
            this.savedSearch = this.allVacancies[0];
          }
        */
      });
    },

      getLatestSearches() {

        this.input_save_vacancy.user_id = store.state.user_id;


        console.log('inside get all getLatestSearches ')

        this.axios({
          method: 'post',
          url: '' + this.$hostname + '/search-candidates/method-mysql-naive/get-all-searches?access_token=' + store.state.token,
          data: this.input_save_vacancy,
        }).then((res) => {
          this.latestSearches = res.data.latestSearches;
        });

    },

    removeSearch(ix) {

      this.input_save_vacancy.user_id = store.state.user_id;
      this.input_save_vacancy.name = this.latestSearches[ix].hash

      console.log('inside get all removeSearch ')

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/search-candidates/method-mysql-naive/update-searches?access_token=' + store.state.token,
        data: this.input_save_vacancy,
      }).then((res) => {
        this.getLatestSearches();
      });

    },



    paginationUtil(x) {
      this.page_arr=[]
      this.page.active = parseInt(x);
      this.page.up = Math.min(this.page.active+1, this.page.max);
      this.page.down = Math.max(this.page.active-1, this.page.min);
      if (this.page.active>this.page.min) {
        this.page_arr.push({page: '' + this.page.min, active: 'notactive'});
      }
      if (this.page.active === this.page.min) {
        this.page.prev = false;
      }
      if (this.page.active === this.page.max) {
        this.page.next = false;
      }

      if (this.page.active-this.page.min>=this.page.skip_number) {
        this.page.left_skip = true;
        this.page_arr.push({page:'...',active:'notactive'});
        this.page_arr.push({page:''+this.page.down,active:'notactive'});
        if (this.page.active<this.page.max) {
          this.page_arr.push({page: '' + this.page.active, active: 'active'});
        }
      } else {

        for (var i=2; i<this.page.active; i++) {
          this.page_arr.push({page:''+i,active:'notactive'});
        }
        if (this.page.active<this.page.max) {
          this.page_arr.push({page:''+this.page.active,active:'active'});
        }

      }
      if (this.page.max-this.page.active>=this.page.skip_number) {
        this.page.right_skip = true;
        this.page_arr.push({page:''+this.page.up,active:'notactive'});
        this.page_arr.push({page:'...',active:'notactive'});
        this.page_arr.push({page:''+this.page.max,active:'notactive'});

      } else {
        for (var i = this.page.up; i <= this.page.max; i++) {

          if (this.page.max===i & this.page.active === this.page.max) {
            this.page_arr.push({page: '' + i, active: 'active'});
          } else {
            this.page_arr.push({page: '' + i, active: 'notactive'});
          }
        }

      }
      console.log(this.page_arr);
      console.log(this.page);
    },



    PaginationStep(x){
      console.log('page ' + x)
      if (x===-1) {
        //let route = this.$router.resolve({path: '/search-candidates/'+this.$route.params.search_hash+'/page/'+this.page.down});
        //window.open(route.href, '_self');
        this.loadingStatus=true;

      //  this.$vs.loading({
      //    container: '#div-with-loading',
      //    scale: 1,
      //    opacity: 0.1,
      //  });

        this.search_parameters_weaviate.offset = '' + Math.max(1,parseInt(this.search_parameters_weaviate.offset)-25);
        console.log(this.search_parameters_weaviate)
        this.axios({
          method: 'post',
          url: '' + this.$hostname +  '/weaviate/search-resumes-like',
          data: this.search_parameters_weaviate,
        }).then((res) => {
          this.resumes=[];
          console.log(res.data)
          if (res.data.length) {
            for (var k = 0; k < res.data.length; k++) {
              this.resumes.push(res.data[k]);
            }

          }

          //this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loadingStatus=false;
        }).catch((error) => {
          //this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loadingStatus=false;
        });
        this.paginationUtil(this.page.active-1)
      } else if (x===-2) {
        //let route = this.$router.resolve({path: '/search-candidates/'+this.$route.params.search_hash+'/page/'+this.page.up});
        //window.open(route.href, '_self');

        this.loadingStatus=true;

     //   this.$vs.loading({
     //     container: '#div-with-loading',
     //     scale: 1,
      //    opacity: 0.1,
      //  });

        this.search_parameters_weaviate.offset = '' + Math.max(1,parseInt(this.search_parameters_weaviate.offset)+25);
        console.log(this.search_parameters_weaviate)
        this.axios({
          method: 'post',
          url: '' + this.$hostname +  '/weaviate/search-resumes-like',
          data: this.search_parameters_weaviate,
        }).then((res) => {
          this.resumes=[];
          console.log(res.data)
          if (res.data.length) {
            for (var k = 0; k < res.data.length; k++) {
              this.resumes.push(res.data[k]);
            }

          }

        //  this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loadingStatus=false;
        }).catch((error) => {
          //this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loadingStatus=false;
        });
        this.paginationUtil(this.page.active+1)
      } else {
       // let route = this.$router.resolve({path: '/search-candidates/'+this.$route.params.search_hash+'/page/'+x});
       // window.open(route.href, '_self');

        this.loadingStatus=true;

      //  this.$vs.loading({
      //    container: '#div-with-loading',
      //    scale: 1,
      //    opacity: 0.1,
      //  });

        //this.search_parameters_weaviate.offset = '' + (Math.min(this.total_number_of_resumes,Math.max(1,(x-1)*25+1)));
        this.search_parameters_weaviate.offset = '' + ((x-1)*25+1);
        console.log(this.search_parameters_weaviate)
        this.axios({
          method: 'post',
          url: '' + this.$hostname +  '/weaviate/search-resumes-like',
          data: this.search_parameters_weaviate,
        }).then((res) => {
          this.resumes=[];
          console.log(res.data)
          if (res.data.length) {
            for (var k = 0; k < res.data.length; k++) {
              this.resumes.push(res.data[k]);
            }

          }

        //  this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loadingStatus=false;
        }).catch((error) => {
         // this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          this.loadingStatus=false;
        });
        this.paginationUtil(x)
      }

    },




  },


};
</script>

<template>
  <div>
    <Navbar :nav-light="true" />


    <!-- Search Start -->
    <section class="section-two bg-light">

      <div class="container mt-5 vs-con-loading__container" id="div-with-loading">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <form class="p-4 shadow bg-white rounded">
              <div class="d-flex justify-content-between">
                <h4 class="mb-3">Поиск резюме</h4>

              </div>
              <div class="row">
                <div class="col-10 ">


                  <div class="input-group-append form-group">


                    <input
                        name="name"
                        id="name"
                        type="text"
                        class="form-control rounded-left col-8"
                        placeholder="data science +python +senior"
                        v-model="search_parameters_weaviate.message"
                    />
                    <select class="form-control custom-select rounded-0 col-2" v-model="field_Selected">
                      <option v-bind:value="x" v-for="x in field_Options">{{x.value}}</option>
                    </select>

                    <a
                        class="btn btn-primary btn-block "
                        @click="searchWeaviateResumes"
                    > <b-spinner v-if="loadingStatus"></b-spinner>  Поиск </a>

                  </div>

                </div>


              </div>


            </form>

          </div>

        </div>

      </div>


      <!--end container-->
    </section>




    <!-- Job List Start -->
    <!--<section class="section">-->
    <div v-if="resumes.length">
      <div class="row m-4">
        <div class="col-lg-3 col-md-6 col-12">
          <input
              class="searchbtn btn btn-primary btn-block"
              value="Создать вакансию"
              @click="$router.push({path:'/new-job-edit'})"
          />
        </div>
        <div class="col-lg-8 col-md-6 col-12">
           <h3>Найдено {{ new Intl.NumberFormat().format(totalSearchResults).replace(',', ' ') }} резюме </h3>
          </div>
      </div>
        <div class="row ml-4 mr-4">
          <div class="col-lg-3 col-md-6 col-12">
            <div class="sidebar sticky-bar  p-4 rounded shadow">
              <!-- SEARCH
              <div class="widget mb-4 pb-4 border-bottom">
                <h5 class="widget-title">Search Keywords</h5>
                <div id="jobkeywords" class="widget-search mt-4 mb-0">
                  <form role="search" method="get" id="searchform" class="searchform">
                    <div>
                      <input type="text" class="border rounded" name="s" id="s" placeholder="Search Keywords...">
                      <input type="submit" id="searchsubmit" value="Search">
                    </div>
                  </form>
                </div>
              </div>
              -->
              <!-- SEARCH -->

              <!--
              <div class="widget mb-4 pb-2 border-bottom">
                <div class=" d-flex justify-content-between">
                <h5 class="widget-title">Опыт в индустрии</h5>
                  <plus-icon class="fea"  @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                  @click="filters.industryFilter=!filters.industryFilter"></plus-icon>
                </div>
                <div class="tagcloud mt-4" v-if="filters.industryFilter">
                  <a href="jvascript:void(0)" class="rounded">Business</a>
                  <a href="jvascript:void(0)" class="rounded">Finance</a>
                  <a href="jvascript:void(0)" class="rounded">Marketing</a>
                  <a href="jvascript:void(0)" class="rounded">Fashion</a>
                  <a href="jvascript:void(0)" class="rounded">Bride</a>
                  <a href="jvascript:void(0)" class="rounded">Lifestyle</a>
                  <a href="jvascript:void(0)" class="rounded">Travel</a>
                  <a href="jvascript:void(0)" class="rounded">Beauty</a>
                  <a href="jvascript:void(0)" class="rounded">Video</a>
                  <a href="jvascript:void(0)" class="rounded">Audio</a>
                </div>
              </div>
              -->

              <!-- CATAGORIES -->

              <!--
              <div class="widget mb-4 pb-4 border-bottom">

                <div class=" d-flex justify-content-between">
                  <h5 class="widget-title">Уровень позиции</h5>
                  <plus-icon class="fea"  @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                             @click="filters.gradeFilter=!filters.gradeFilter"></plus-icon>
                </div>
                <div class="form-group mt-4 mb-0" v-if="filters.gradeFilter">
                  <select class="form-control custom-select" id="job-catagories">
                    <option>All Jobs</option>
                    <option>Digital & Creative</option>
                    <option>Accountancy</option>
                    <option>Banking</option>
                    <option>IT Contractor</option>
                    <option>Graduate</option>
                    <option>Estate Agency</option>
                  </select>
                </div>
              </div>

              -->

              <!-- CATAGORIES -->

              <!-- Posted -->
              <!--
              <div class="widget mb-4 pb-4 border-bottom">

                <div class=" d-flex justify-content-between">
                  <h5 class="widget-title">Последняя активность на сайтах</h5>
                  <plus-icon class="fea"  @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                             @click="filters.lastActiveFilter=!filters.lastActiveFilter"></plus-icon>
                </div>
                <div class="mt-4" v-if="filters.lastActiveFilter">
                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="lasthour" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="lasthour">Last Hour</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="oneday" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="oneday">Last 24 hours</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="sevenday" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="sevenday">Last 7 days</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="fourteenday" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="fourteenday">Last 14 days</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-0">
                      <input type="radio" id="onemonth" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="onemonth">Last 30 days</label>
                    </div>
                  </div>
                </div>
              </div>
              -->
              <!-- Posted -->

              <!-- Job Type -->
              <!--
              <div class="widget mb-4 pb-4 border-bottom">

                <div class=" d-flex justify-content-between">
                  <h4 class="widget-title">Тип работы</h4>
                  <plus-icon class="fea"  @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                             @click="filters.jobTypeFilter=!filters.jobTypeFilter"></plus-icon>
                </div>
                <div class="mt-4" v-if="filters.jobTypeFilter">
                  <div class="form-group mb-2">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="contract">
                      <label class="custom-control-label" for="contract">Contract Base</label>
                    </div>
                  </div>

                  <div class="form-group mb-2">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="freelancer">
                      <label class="custom-control-label" for="freelancer">Freelancer</label>
                    </div>
                  </div>

                  <div class="form-group mb-2">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="fulltime">
                      <label class="custom-control-label" for="fulltime">Full Time</label>
                    </div>
                  </div>

                  <div class="form-group mb-2">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="parttime">
                      <label class="custom-control-label" for="parttime">Part Time</label>
                    </div>
                  </div>

                  <div class="form-group mb-2">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="remote">
                      <label class="custom-control-label" for="remote">Remote</label>
                    </div>
                  </div>

                  <div class="form-group mb-0">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="intership">
                      <label class="custom-control-label" for="intership">Intership</label>
                    </div>
                  </div>
                </div>
              </div>
               -->


              <div class="widget mb-4 pb-4 border-bottom" id="div-with-loading-city">

                <div class=" d-flex justify-content-between">
                  <h4 class="widget-title">Есть Email</h4>
                  <minus-icon class="fea" v-if="filters.emailFilter" @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                              @click="filters.emailFilter=!filters.emailFilter"></minus-icon>

                  <plus-icon class="fea" v-else @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                             @click="filters.emailFilter=!filters.emailFilter"></plus-icon>
                </div>
                <div class="mt-4" v-if="filters.emailFilter">
                  <div class="mb-2" v-for="(x,ix) in is_email_filter_arr">
                    <div class="form-check form-check-inline">
                      <div class="form-group mb-0">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" :id="'email'+ix"
                                 v-model="x.flag" v-on:change="selectionEmailChanged(x.value)" >
                          <label class="custom-control-label" :for="'email'+ix">{{x.value}} </label>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>



              <div class="widget mb-4 pb-4 border-bottom" id="div-with-loading-city">

                <div class=" d-flex justify-content-between">
                  <h4 class="widget-title">Город</h4>
                  <minus-icon class="fea" v-if="filters.cityFilter" @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                             @click="filters.cityFilter=!filters.cityFilter"></minus-icon>

                  <plus-icon class="fea" v-else @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                             @click="filters.cityFilter=!filters.cityFilter"></plus-icon>
                </div>
                <div class="mt-4" v-if="filters.cityFilter">
                  <div class="mb-2" v-for="(x,ix) in cities_filter_arr" v-if="ix<cityLimit">
                    <div class="form-check form-check-inline">
                      <div class="form-group mb-0">
                      <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input"
                             :id="'city'+ix" v-model="x.flag" v-on:change="selectionCityChanged(x)" >
                      <label class="custom-control-label" :for="'city'+ix">{{x.value}} <i>({{x.count}})</i></label>
                    </div>
                  </div>
                    </div>
                  </div>
                  <div class="mt-2"> <b @click="showAll('city','more')" v-if="cityLimit===5"
                                        @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }">
                    Показать все</b>
                    <b @click="showAll('city','less')" v-if="cityLimit===100"
                       @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }">
                      Показать меньше</b>
                  </div>

                </div>
              </div>



              <div class="widget mb-4 pb-4 border-bottom" id="div-with-loading-city">

                <div class=" d-flex justify-content-between">
                  <h4 class="widget-title">Возраст</h4>
                  <minus-icon class="fea" v-if="filters.ageFilter" @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                              @click="filters.ageFilter=!filters.ageFilter"></minus-icon>

                  <plus-icon class="fea" v-else @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                             @click="filters.ageFilter=!filters.ageFilter"></plus-icon>
                </div>
                <div class="mt-4" v-if="filters.ageFilter">
                  <div class="mb-2" v-for="(x,ix) in age_filter_arr">
                    <div class="form-check form-check-inline">
                      <div class="form-group mb-0">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" :id="'age'+ix"
                                 v-model="x.flag" v-on:change="selectionAgeChanged(x.value)" >
                          <label class="custom-control-label" :for="'age'+ix">{{x.value}} </label>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              <div class="widget mb-4 pb-4 border-bottom" id="div-with-loading-city">

                <div class=" d-flex justify-content-between">
                  <h4 class="widget-title">Опыт</h4>
                  <minus-icon class="fea" v-if="filters.experienceFilter" @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                              @click="filters.experienceFilter=!filters.experienceFilter"></minus-icon>

                  <plus-icon class="fea" v-else @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                             @click="filters.experienceFilter=!filters.experienceFilter"></plus-icon>
                </div>
                <div class="mt-4" v-if="filters.experienceFilter">
                  <div class="mb-2" v-for="(x,ix) in experience_filter_arr">
                    <div class="form-check form-check-inline">
                      <div class="form-group mb-0">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input"
                                 :id="'exp'+ix" v-model="x.flag" v-on:change="selectionExperienceChanged(x.value)" >
                          <label class="custom-control-label" :for="'exp'+ix">{{x.value}} </label>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>



              <div class="widget mb-4 pb-4 border-bottom" id="div-with-loading-company">

                <div class=" d-flex justify-content-between">
                  <h4 class="widget-title">Компания</h4>
                  <minus-icon class="fea" v-if="filters.companyFilter" @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                              @click="filters.companyFilter=!filters.companyFilter"></minus-icon>

                  <plus-icon class="fea" v-else @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                             @click="filters.companyFilter=!filters.companyFilter"></plus-icon>
                </div>
                <div class="mt-4 form-group" v-if="filters.companyFilter">
                  <div class=" mb-2" v-for="(x,ix) in companies_filter_arr" v-if="ix<companiesLimit">
                    <div class="form-check form-check-inline">
                      <div class="form-group mb-0">
                    <div class="custom-control custom-checkbox" >
                      <input type="checkbox" class="custom-control-input"
                             :id="'company'+ix" v-model="x.flag" v-on:change="selectionCompanyChanged(x)" >
                      <label class="custom-control-label" :for="'company'+ix">{{x.value}} <i>({{x.count}})</i></label>
                    </div>
                  </div>
                    </div>
                  </div>
                  <div class="mt-2"> <b @click="showAll('company','more')" v-if="companiesLimit===5"
                                        @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }">
                    Показать все</b>
                    <b @click="showAll('company','less')" v-if="companiesLimit===100"
                       @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }">
                      Показать меньше</b>
                  </div>
                </div>
              </div>

              <div class="widget mb-4 pb-4 border-bottom" id="div-with-loading-university">

                <div class=" d-flex justify-content-between">
                  <h4 class="widget-title">Университет</h4>
                  <minus-icon class="fea" v-if="filters.universityFilter" @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                              @click="filters.universityFilter=!filters.universityFilter"></minus-icon>

                  <plus-icon class="fea" v-else @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                             @click="filters.universityFilter=!filters.universityFilter"></plus-icon>
                </div>
                <div class="mt-4 form-group mb-2" v-if="filters.universityFilter">
                  <div v-for="(x,ix) in universities_filter_arr" v-if="ix<universityLimit">
                    <div class="form-check form-check-inline">
                      <div class="form-group mb-0">
                    <div class="custom-control custom-checkbox" >
                      <input type="checkbox" class="custom-control-input"
                             :id="'uni'+ix" v-model="x.flag" v-on:change="selectionUniversityChanged(x)" >
                      <label class="custom-control-label" :for="'uni'+ix">{{x.value}} <i>({{x.count}})</i></label>
                    </div>
                  </div>
                    </div>
                  </div>
                  <div class="mt-2"> <b @click="showAll('university','more')" v-if="universityLimit===5"
                           @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }">
                    Показать все</b>
                    <b @click="showAll('university','less')" v-if="universityLimit===100"
                       @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }">
                      Показать меньше</b>
                  </div>
                </div>
              </div>


              <div class="widget mb-4 pb-4 border-bottom" id="div-with-loading-city">

                <div class=" d-flex justify-content-between">
                  <h4 class="widget-title">Работа удаленно</h4>
                  <minus-icon class="fea" v-if="filters.remoteFilter" @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                              @click="filters.remoteFilter=!filters.remoteFilter"></minus-icon>

                  <plus-icon class="fea" v-else @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                             @click="filters.remoteFilter=!filters.remoteFilter"></plus-icon>
                </div>
                <div class="mt-4" v-if="filters.remoteFilter">
                  <div class="mb-2" v-for="(x,ix) in is_remote_filter_arr">
                    <div class="form-check form-check-inline">
                      <div class="form-group mb-0">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input"
                                 :id="'remote'+ix" v-model="x.flag" v-on:change="selectionRemoteChanged(x.value)" >
                          <label class="custom-control-label" :for="'remote'+ix">{{x.value}} </label>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>


              <div class="widget mb-4 pb-4 border-bottom" id="div-with-loading-city">

                <div class=" d-flex justify-content-between">
                  <h4 class="widget-title">Готовы к переезду</h4>
                  <minus-icon class="fea" v-if="filters.reallocateFilter" @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                              @click="filters.reallocateFilter=!filters.reallocateFilter"></minus-icon>

                  <plus-icon class="fea" v-else @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                             @click="filters.reallocateFilter=!filters.reallocateFilter"></plus-icon>
                </div>
                <div class="mt-4" v-if="filters.reallocateFilter">
                  <div class="mb-2" v-for="(x,ix) in is_reallocate_filter_arr">
                    <div class="form-check form-check-inline">
                      <div class="form-group mb-0">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input"
                                 :id="'realloc'+ix" v-model="x.flag" v-on:change="selectionReallocateChanged(x.value)" >
                          <label class="custom-control-label" :for="'realloc'+ix">{{x.value}} </label>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div class="widget mb-4 pb-4 border-bottom" id="div-with-loading-city">

                <div class=" d-flex justify-content-between">
                  <h4 class="widget-title">Ожидаемый доход</h4>
                  <minus-icon class="fea" v-if="filters.salaryFilter" @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                              @click="filters.salaryFilter=!filters.salaryFilter"></minus-icon>

                  <plus-icon class="fea" v-else @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                             @click="filters.salaryFilter=!filters.salaryFilter"></plus-icon>
                </div>
                <div class="mt-4" v-if="filters.salaryFilter">
                  <div class="mb-2" v-for="(x,ix) in salary_filter_arr">
                    <div class="form-check form-check-inline">
                      <div class="form-group mb-0">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input"
                                 :id="'salary'+ix" v-model="x.flag" v-on:change="selectionSalaryChanged(x.value)" >
                          <label class="custom-control-label" :for="'salary'+ix">{{x.value}} </label>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <!--
              <div class="widget">
                <div class=" d-flex justify-content-between">
                  <h4 class="widget-title">Experience</h4>
                  <plus-icon class="fea"  @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                             @click="filters.experianceFilter=!filters.experianceFilter"></plus-icon>
                </div>

                <div class="mt-4" v-if="filters.experianceFilter">
                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="freshers" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="freshers">Fresher</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="oneyearex" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="oneyearex">+1 Year</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="twoyearex" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="twoyearex">+2 Year</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-2">
                      <input type="radio" id="threeyearex" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="threeyearex">+3 Year</label>
                    </div>
                  </div>

                  <div class="custom-control custom-radio">
                    <div class="form-group mb-0">
                      <input type="radio" id="fiveyearex" name="customRadio" class="custom-control-input">
                      <label class="custom-control-label" for="fiveyearex">+5 Year</label>
                    </div>
                  </div>
                </div>
              </div>
              -->
              <!-- Posted -->
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-9 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">

            <div class="row">
              <div class="col-lg-12 col-12 mb-4 pb-2" v-for="(resume,ix) in resumes">
              <div class="widget">
                <ResumeHighlight
                    :resume="resume"
                    :vacancyNames="activeVacancies"
                    @index="updateResume"
                    :index="ix"
                />
              </div>
              </div>
            </div>

            <div class="widget">
              <ul class="pagination justify-content-center mb-0">
                <li class="page-item"
                    @click="PaginationStep(-1)">
                  <a class="page-link" @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                     v-if="page.prev" aria-label="Previous" v-scroll-to="'#topnav'">Пред</a></li>

                <li class="page-item"
                    :class="x.active" v-for="x in page_arr" @click="PaginationStep(x.page)">
                  <a class="page-link" @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                     v-scroll-to="'#topnav'">{{x.page}}</a></li>

                <li class="page-item"
                    @click="PaginationStep(-2)">
                  <a class="page-link" @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                     v-if="page.next" aria-label="Next" v-scroll-to="'#topnav'">След</a></li>
              </ul>
            </div>


          </div>

        </div>

   <!-- </section> -->
    </div>






    <Footer />
    <!--
    <Switcher />
    -->

    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
